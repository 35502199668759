import React from 'react';
import { useMatches } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useWebSocket from 'react-use-websocket';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import Fab from '@mui/material/Fab';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CloseIcon from '@mui/icons-material/Close';
import Zoom from '@mui/material/Zoom';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import { RouteHandle } from '../../../handles';
import { ApiClient } from '../../../api';

interface AIChatBotClusterProps {
  siteId: number;
}

const ChatbotSvgIcon: React.FC = (props: { fontSize?: number | string | undefined }) => (
  <SvgIcon sx={{ fontSize: props.fontSize ?? 28 }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1875 3.0625C16.1875 3.95951 15.6476 4.73042 14.875 5.06798V7H19.25C23.116 7 26.25 10.134 26.25 14C26.25 16.6815 24.7422 19.0109 22.5282 20.1865L22.5312 20.1865L22.4157 20.2449L22.3927 20.2566L14 24.5V21H8.75C4.88401 21 1.75 17.866 1.75 14C1.75 10.134 4.88401 7 8.75 7H13.125V5.06798C12.3524 4.73042 11.8125 3.95951 11.8125 3.0625C11.8125 1.85438 12.7919 0.875 14 0.875C15.2081 0.875 16.1875 1.85438 16.1875 3.0625ZM13.125 8.75H8.75C5.85051 8.75 3.5 11.1005 3.5 14C3.5 16.8995 5.8505 19.25 8.75 19.25H19.25C22.1495 19.25 24.5 16.8995 24.5 14C24.5 11.1005 22.1495 8.75 19.25 8.75H14.875H13.125ZM8.75 10.5C6.817 10.5 5.25 12.067 5.25 14C5.25 15.933 6.817 17.5 8.75 17.5H19.25C21.183 17.5 22.75 15.933 22.75 14C22.75 12.067 21.183 10.5 19.25 10.5H8.75ZM10.0625 15.3125C10.7874 15.3125 11.375 14.7249 11.375 14C11.375 13.2751 10.7874 12.6875 10.0625 12.6875C9.33763 12.6875 8.75 13.2751 8.75 14C8.75 14.7249 9.33763 15.3125 10.0625 15.3125ZM19.25 14C19.25 14.7249 18.6624 15.3125 17.9375 15.3125C17.2126 15.3125 16.625 14.7249 16.625 14C16.625 13.2751 17.2126 12.6875 17.9375 12.6875C18.6624 12.6875 19.25 13.2751 19.25 14Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

const AIChatBotCluster: React.FC<AIChatBotClusterProps> = ({ siteId }) => {
  const [open, setOpen] = React.useState(false);
  const [onfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const { data } = useQuery({
    queryFn: () => ApiClient.dueDiligence.getChatBotSession({ siteId }),
    queryKey: ['chatbot-session', { siteId }],
    staleTime: Number.MAX_SAFE_INTEGER
  });

  useWebSocket(
    data && open
      ? `https://ml-dev-720699231124.us-central1.run.app/chatbot/chat?token=${data.token.access_token}`
      : null
  );

  const handleOpenChat = () => setOpen(true);
  const handleCloseChat = () => setOpen(false);
  const handleOpenResetConfirmation = () => setConfirmationDialogOpen(true);
  const handleCloseResetConfirmation = () => setConfirmationDialogOpen(false);
  const handleResetConfirm = () => {
    // TODO: implement reset action
  };

  return (
    <>
      <Zoom
        in={!open}
        style={{
          transitionDelay: `500ms`,
          transitionDuration: `${open ? 1000 : 300}ms`
        }}
      >
        <Fab
          color="primary"
          size="medium"
          sx={{
            position: 'fixed',
            bottom: 30,
            right: 30,
            boxShadow: 'none',
            background: 'linear-gradient(245.75deg, #456CF3 7.17%, #8D4BE9 89.9%)'
          }}
          onClick={handleOpenChat}
        >
          <ChatbotSvgIcon />
        </Fab>
      </Zoom>
      <Modal
        disableEscapeKeyDown
        disableScrollLock
        hideBackdrop
        keepMounted
        sx={{
          top: 'auto',
          left: 'auto',
          bottom: 30,
          right: 30
        }}
        open={open}
      >
        <Zoom in={open} style={{ transformOrigin: 'bottom right' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '25vw',
              maxWidth: '800px',
              minWidth: '320px',
              height: '60vh',
              minHeight: '120px',
              maxHeight: '1200px',
              backgroundColor: '#FFFFFF',
              boxShadow:
                'rgba(0, 0, 0, 0.08) 0px 5px 22px 4px, rgba(0, 0, 0, 0.1) 0px 12px 17px 2px, rgba(0, 0, 0, 0.16) 0px 7px 8px -4px'
            }}
          >
            <AppBar
              sx={{ boxShadow: 'none', background: 'linear-gradient(245.75deg, #456CF3 7.17%, #8D4BE9 89.9%)' }}
              position="static"
              component="div"
            >
              <Toolbar sx={{ paddingX: '20px !important' }}>
                <Typography variant="h6">AI Assistant</Typography>
                <Box sx={{ flexGrow: 1, mr: '12px' }} />
                <Box display="flex" color="#FFFFFF" gap="4px">
                  <Tooltip
                    componentsProps={{
                      tooltip: { sx: { bgcolor: '#121212' } },
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -10]
                            }
                          }
                        ]
                      }
                    }}
                    title="Reset Conversation"
                  >
                    <IconButton color="inherit" onClick={handleOpenResetConfirmation}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    componentsProps={{
                      tooltip: { sx: { bgcolor: '#121212' } },
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -10]
                            }
                          }
                        ]
                      }
                    }}
                    title="Collapse"
                  >
                    <IconButton color="inherit" onClick={handleCloseChat}>
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Toolbar>
            </AppBar>
            <Box sx={{ flexGrow: 1, flexShrink: 1, overflowY: 'scroll', overflow: 'auto' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px',
                  gap: '20px'
                }}
              >
                <Box display="flex" gap="10px" alignItems="flex-end">
                  <Avatar
                    sx={{
                      height: '44px',
                      width: '44px',
                      background: 'linear-gradient(245.75deg, #456CF3 7.17%, #8D4BE9 89.9%)'
                    }}
                  >
                    <ChatbotSvgIcon />
                  </Avatar>
                  <Box display="flex" gap="10px" flexDirection="column" alignItems="flex-start">
                    <Typography variant="body2" px="16px" py="12px" bgcolor="rgba(0, 0, 0, 0.04)">
                      Hi! I’m Riley, your AI assistant. You can ask me about the documents related to the Nutting Ridge
                      project.
                    </Typography>
                    <Typography variant="body2" px="16px" py="12px" bgcolor="rgba(0, 0, 0, 0.04)">
                      How can I help?
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" gap="10px" justifyContent="flex-end">
                  <Typography variant="body2" ml="54px" px="16px" py="12px" bgcolor="#121212" color="#FFFFFF">
                    What are the payment terms?
                  </Typography>
                </Box>
                <Box display="flex" gap="10px" alignItems="flex-end">
                  <Avatar
                    sx={{
                      height: '44px',
                      width: '44px',
                      background: 'linear-gradient(245.75deg, #456CF3 7.17%, #8D4BE9 89.9%)'
                    }}
                  >
                    <ChatbotSvgIcon />
                  </Avatar>
                  <Box display="flex" gap="10px" flexDirection="column" alignItems="flex-start">
                    <Typography variant="body2" px="16px" py="12px" bgcolor="rgba(0, 0, 0, 0.04)">
                      Here is my response based on the context provided:
                      <br />
                      The payment terms for rent are outlined in Section 3 of the agreement:
                      <br />
                      (a) During the Construction Period from the Commencement Date until the Commercial Operation Date,
                      pay Owner $1.00 as Construction Period rent.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '4px',
                padding: '12px 20px 12px 20px',
                borderTop: '1px solid #E0E0E0',
                flexShrink: 0
              }}
            >
              <TextField
                size="small"
                multiline
                minRows={1}
                maxRows={5}
                variant="outlined"
                placeholder="Ask a question..."
                sx={{ flexGrow: 1, my: 'auto', '& fieldset': { border: 'none' } }}
                InputProps={{
                  sx: {
                    padding: 0,
                    border: 'none'
                  }
                }}
              />
              <IconButton sx={{ color: 'rgba(0, 0, 0, 0.26)', mr: '-8px' }}>
                <SendRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </Zoom>
      </Modal>
      <Dialog open={onfirmationDialogOpen} onClose={handleCloseResetConfirmation}>
        <DialogTitle sx={{ backgroundColor: '#121212', color: '#FFFFFF' }}>Reset conversation?</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseResetConfirmation}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: 'rgba(255, 255, 255, 0.56)'
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: '8px 24px 8px 24px !important' }}>
          <DialogContentText>This will clear all previous messages and start a new chat.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetConfirmation}>Cancel</Button>
          <Button onClick={handleResetConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const AIAssistant: React.FC = () => {
  const matches = useMatches();
  const routeMatch = matches.find(({ handle, data }) => handle && data);
  if (!routeMatch) return null;

  const [data, featuresMap] =
    routeMatch.handle instanceof RouteHandle ? [routeMatch.data, routeMatch.handle.getFeaturesMap()] : [null, null];

  const config = featuresMap?.['ai-assistant']?.generateConfig(data);

  if (!config) return null;

  const { siteId, enabled } = config;

  if (!enabled) return null;

  return <AIChatBotClusterWrapper siteId={siteId} />;
};

const AIChatBotClusterWrapper: React.FC<AIChatBotClusterProps> = ({ siteId }) => {
  return React.useMemo(() => <AIChatBotCluster siteId={siteId} />, [siteId]);
};

export default AIAssistant;
