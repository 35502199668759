import React from 'react';
import { useQuery } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CircularProgress } from '@mui/material';
import Companies from './components/Companies';
import Sites from './components/Sites';
import ActualProduction from '../../../../components/charts/ActualProduction/ActualProduction';
import { ApiClient } from '../../../../api';

const Loading: React.FC = () => (
  <Box position="absolute" width="100%" border="1px solid transparent" height="calc(100% - 32px)">
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bgcolor="#FFFFFF"
    >
      <CircularProgress />
    </Box>
  </Box>
);

const MessageOverlay: React.FC<{ msg: string }> = ({ msg }) => (
  <Box position="absolute" width="100%" border="1px solid transparent" height="calc(100% - 32px)">
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bgcolor="#FFFFFF"
    >
      <Typography variant="body1" textAlign="center" width="70%">
        {msg}
      </Typography>
    </Box>
  </Box>
);

export const PortfolioPage: React.FC = () => {
  const [selectedCompany, setSelectedCompany] = React.useState<{ id: number; name: string } | null>(null);
  const [companiesDataRendered, setCompaniesDataRendered] = React.useState<boolean>(false);

  const {
    data: companyPerformanceData,
    isFetching: isFetchingCompanyPerformanceData,
    error: errorLoadingCompanyPerformanceData
  } = useQuery({
    queryFn: () => ApiClient.investorDashboard.companyAggregatedPerformance(selectedCompany?.id ?? -1),
    queryKey: ['companies', 'aggregated-performance', { companyId: selectedCompany?.id }],
    enabled: !!selectedCompany
  });

  const actualProductionPlaceholderData = {
    id: 0,
    total_sites: 0,
    total_actual_kw: 0,
    total_expected_kw: 0,
    total_system_size_ac: 0,
    total_system_size_dc: 0,
    actual_vs_expected: 0
  };

  const onCompaniesDataRendered = React.useCallback(
    () => setTimeout(() => setCompaniesDataRendered(true), 0),
    [setCompaniesDataRendered]
  );

  return (
    <Box maxWidth="1800px" mx="auto" paddingTop="0" paddingBottom="24px" sx={{ flexGrow: 1 }}>
      <Typography variant="h4" marginBottom="24px" sx={{ fontWeight: 600 }} fontSize="34px" lineHeight="42px">
        My Portfolio
      </Typography>
      <Grid container spacing={2} pt="-24px">
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <Box
            sx={{
              mt: '16px',
              borderRight: '1px solid #0000001F',
              borderLeft: '1px solid #0000001F',
              borderTop: '1px solid #0000001F'
            }}
          >
            <Typography variant="h6" fontSize="24px" p="16px">
              Companies
            </Typography>
          </Box>
          <Companies onCompaniesDataRendered={onCompaniesDataRendered} onCompanySelected={setSelectedCompany} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Box py="16px" position="relative" display="flex">
            <ActualProduction
              title={selectedCompany?.name ? `${selectedCompany?.name} Production` : 'Actual Production'}
              scope="investor-dashboard"
              data={companyPerformanceData ?? actualProductionPlaceholderData}
            />
            {(!companiesDataRendered || isFetchingCompanyPerformanceData) && <Loading />}
            {!isFetchingCompanyPerformanceData && errorLoadingCompanyPerformanceData && (
              <MessageOverlay msg="An error occurred when retrieving the company aggregated performance data" />
            )}
            {!isFetchingCompanyPerformanceData && companiesDataRendered && !companyPerformanceData && (
              <MessageOverlay msg="No Data" />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: '16px',
              borderRight: '1px solid #0000001F',
              borderLeft: '1px solid #0000001F',
              borderTop: '1px solid #0000001F'
            }}
          >
            <Typography variant="h6" fontSize="24px" p="16px">
              Sites
            </Typography>
          </Box>
          <Sites />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PortfolioPage;
