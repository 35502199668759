export const COMPANY_TYPES = [
  'O&M Contractor',
  'Project/Site Owner',
  'EPC Contractor',
  'Bank',
  'Appraiser',
  'Engineering Firm',
  'Law Firm',
  'Investor',
  'Subscriber Manager',
  'Insurance Company'
];

export const MAX_FILE_SIZE = 100 * 1024 * 1024;
