import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

import WeatherIndicator from '../../../components/common/WeatherIndicator/WeatherIndicator';
import { formatFloatValue } from '../../../utils/formatters/formatFloatValue';

interface WidgetContainerScoped extends BoxProps {
  scope?: 'O&M' | 'investor-dashboard';
}

export const WidgetContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'scope'
})<WidgetContainerScoped>(({ scope }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '16px',
  border: '1px solid',
  borderColor: scope !== 'investor-dashboard' ? '#0000003B' : '#0000001F',
  height: '100%',
  minHeight: '330px'
}));

interface ActualProductionCommonProps {
  title: string;
}

interface ActualProductionOMScopeProps extends ActualProductionCommonProps {
  scope?: 'O&M';
  companyName?: undefined | null;
  data?: {
    actual_vs_expected: number;
    total_actual_kw: number;
    total_expected_kw: number;
    total_sites: number;
    total_system_size_ac: number;
    total_system_size_dc: number;
    weather: 'Sunny' | 'Cloudy' | 'Partly cloudy';
  };
}

interface ActualProductionInvestorDashboardScopeProps extends ActualProductionCommonProps {
  scope: 'investor-dashboard';
  data?: {
    id: number;
    total_sites: number;
    total_actual_kw: number;
    total_expected_kw: number;
    total_system_size_ac: number;
    total_system_size_dc: number;
    actual_vs_expected: number | null;
  };
}

type ActualProductionProps = ActualProductionOMScopeProps | ActualProductionInvestorDashboardScopeProps;

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

const ActualProduction: React.FC<ActualProductionProps> = ({ title, data, scope }) => {
  const theme = useTheme();
  if (!data) return null;

  const {
    actual_vs_expected,
    total_actual_kw,
    total_expected_kw,
    total_sites,
    total_system_size_ac,
    total_system_size_dc
  } = data;

  const actualVsExpected =
    typeof actual_vs_expected === 'number' ? (actual_vs_expected > 100 ? 100 : actual_vs_expected) : 0;
  const actualVsExpectedRest = 100 - actualVsExpected;

  const deriveProductionColorFromValue = (progress: number): string => {
    if (progress < 51) return theme.efficiencyColors.low;
    if (progress < 90) return theme.efficiencyColors.mediocre;
    if (progress < 101) return theme.efficiencyColors.good;
    return theme.efficiencyColors.outstanding;
  };

  const chartData = {
    datasets: [
      {
        data: [actualVsExpected, actualVsExpectedRest],
        backgroundColor: [deriveProductionColorFromValue(actual_vs_expected ?? 0), '#F3F4F8'],
        cutout: '75%'
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    circumference: 180,
    rotation: 270
  };

  const capitalizeString = (str: string): string =>
    str
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

  return (
    <WidgetContainer scope={scope}>
      {scope === 'investor-dashboard' ? (
        <Typography variant="h6" fontSize="24px">
          {title}
        </Typography>
      ) : (
        <Typography variant="h6" mb="6px">
          {title}
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={5} sx={{ position: 'relative', '&.MuiGrid-item': { paddingTop: '0' } }}>
            <Doughnut data={chartData} options={options} />
            <Box
              sx={{
                position: 'absolute',
                left: '54%',
                transform: 'translate(-50%, 0)',
                fontSize: '20px',
                top: '50%',
                textAlign: 'center'
              }}
            >
              {actual_vs_expected}{' '}
              <Typography
                variant="body2"
                display="inline-block"
                fontSize={12}
                color={theme => theme.palette.text.secondary}
              >
                %
              </Typography>
              <Typography variant="body2" fontSize={12} color={theme => theme.palette.text.secondary}>
                from Projected
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', top: '77%', width: '92%' }}
            >
              <Typography variant="body2" fontSize={12} color={theme => theme.palette.text.secondary}>
                0
              </Typography>
              <Typography variant="body2" fontSize={12} color={theme => theme.palette.text.secondary}>
                {formatFloatValue(total_expected_kw)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7} sx={{ '&.MuiGrid-item': { paddingTop: '0' } }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '200px', marginTop: '32px' }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight={700} fontSize={14} lineHeight="20px">
                    {formatFloatValue(total_system_size_ac)} AC / {formatFloatValue(total_system_size_dc)} DC
                  </Typography>
                  <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                    System Size, kW
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight={700} fontSize={14} lineHeight="20px">
                    {total_sites}
                  </Typography>
                  <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                    Sites
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ '&.MuiGrid-item': { paddingTop: '0', marginTop: '-20px' } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                padding: '8px 16px',
                backgroundColor: theme => theme.palette.background.default
              }}
            >
              <Grid
                item
                xs={scope !== 'investor-dashboard' ? 4 : 6}
                sx={{ '&.MuiGrid-item': { borderRight: '1px solid #E0E0E0', marginRight: '16px' } }}
              >
                <Typography variant="h6" fontWeight={700} fontSize={20} lineHeight="32px">
                  {formatFloatValue(total_actual_kw)}
                </Typography>
                <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                  Actual, kW
                </Typography>
              </Grid>
              <Grid
                item
                xs={scope !== 'investor-dashboard' ? 4 : 6}
                sx={
                  scope !== 'investor-dashboard'
                    ? { '&.MuiGrid-item': { borderRight: '1px solid #E0E0E0', marginRight: '16px' } }
                    : undefined
                }
              >
                <Typography variant="h6" fontWeight={700} fontSize={20} lineHeight="32px">
                  {formatFloatValue(total_expected_kw)}
                </Typography>
                <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                  Projected, kW
                  <BootstrapTooltip title="Weather Adjusted Projection (kW)" placement="right">
                    <IconButton sx={{ padding: 0, margin: '0 0 4px 4px' }}>
                      <InfoIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </BootstrapTooltip>
                </Typography>
              </Grid>
              {scope !== 'investor-dashboard' && (
                <Grid item xs={4} sx={{ '&.MuiGrid-item': { textAlign: 'center' } }}>
                  <Typography
                    variant="h6"
                    display="flex"
                    fontWeight={500}
                    fontSize={20}
                    lineHeight="32px"
                    height="32px"
                  >
                    <WeatherIndicator value={data.weather} />
                  </Typography>
                  <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                    {capitalizeString(data.weather)}
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </WidgetContainer>
  );
};

export default ActualProduction;
